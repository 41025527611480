<template>
  <div class="parent-courses bg-inverted-dark">
    <p data-test="parent-courses-title" class="parent-courses__title">
      Available in these courses
    </p>
    <ButtonIcon
      v-for="(course, i) in courses"
      :key="`course-${i}`"
      :href="`${basePath}/courses/${offeringLevel}/${sluggify(course.title)}/`"
      width="fullwidth"
      inverted
      data-test="parent-courses-link"
    >
      {{ course.title }}
    </ButtonIcon>
  </div>
</template>

<script>
import { ButtonIcon } from '@unimelb/pattern-lib-vue';

import { sluggify } from '~/helpers/client';

export default {
  components: { ButtonIcon },
  props: {
    courses: {
      type: Array,
      default: () => [],
    },
    offeringLevel: {
      type: String,
      default: 'undergraduate',
    },
  },
  data() {
    return {
      basePath: process.env.basePath,
    };
  },
  methods: {
    sluggify(text) {
      return sluggify(text);
    },
  },
};
</script>

<style lang="postcss">
.parent-courses {
  padding: var(--sp-3);

  &__title {
    font-weight: var(--fw-semibold);
  }
}
</style>
