<template>
  <div class="at-a-glance">
    <LinkPanel
      v-if="
        offeringType !== 'course' && relatedCourses && relatedCourses.length
      "
      :offering-level="offeringLevel"
      :courses="relatedCourses"
    />
    <CtaPanel :data="ctaButtonsArr" padded />
  </div>
</template>

<script>
import CtaPanel from '~/components/CtaPanel.vue';
import LinkPanel from '~/components/LinkPanel.vue';

export default {
  components: {
    CtaPanel,
    LinkPanel,
  },
  props: {
    offeringType: {
      type: String,
      default: 'course',
    },
    offeringLevel: {
      type: String,
      default: 'undergraduate',
    },
    relatedCourses: {
      type: [Array, Boolean],
      default: false,
    },
    ctaButtonsArr: {
      type: Array,
      default: () => [{}],
    },
    course: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
