<template>
  <SectionAlt
    v-if="relatedStudyAreas.length"
    id="what-can-i-study"
    data-test="what-can-i-study"
  >
    <template #left>
      <h2 class="title title--md">Related study areas</h2>
    </template>
    <template #default>
      <div>
        <ul
          aria-label="Related study areas"
          class="course-list course-list--inline list-reset"
        >
          <li
            v-for="(studyArea, index) in relatedStudyAreas"
            :key="index"
            class="course-list__item"
          >
            <CardCourse
              :title="studyArea.title"
              :link="studyAreaLink(studyArea.slug)"
              type="interest"
            />
          </li>
        </ul>
      </div>
    </template>
  </SectionAlt>
</template>

<script>
import { CardCourse, SectionAlt } from '@unimelb/pattern-lib-vue';
import { get } from '@/helpers/get';

export default {
  components: {
    CardCourse,
    SectionAlt,
  },
  props: {
    course: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      basePath: process.env.basePath,
    };
  },
  computed: {
    relatedStudyAreas() {
      return get(this.course, ['related_interests'], []);
    },
  },
  methods: {
    studyAreaLink(slug) {
      return `${this.basePath}/study-areas/${slug}/`;
    },
  },
};
</script>
